<template>
    <v-container grid-list-md pa-0 relative>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="primary--border" outlined>
            <v-card-title class="title">
              Staff Attendance 
              <v-spacer></v-spacer>
              <v-download-column-select v-if="($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator' ||  $auth.loggedInRole().slug === 'receptionst') &&
                form.items.data.length > 0
                " :showPDF="false" @onDownload="downloadRecord">
                DOWNLOAD STAFF ATTENDANCE
              </v-download-column-select>
            </v-card-title>
            <v-card outlined>
              <v-card-title class="title mb-0 pb-0">
                <v-flex sm4>
                  <v-text-field
                    hint="Atleast 3 characters"
                    outlined
                    dense
                    v-model="search"
                    color="primary"
                    class="pa-0"
                    label="Search by Name, Email or Phone"
                    append-icon="search"
                    />
                </v-flex>
                <v-flex xs2>
                    <v-calendar-field
                      id-val="staff_attendance_date"
                      v-model="attendance_date"
                      label="Attendance Date"
                    >
                    </v-calendar-field>
                </v-flex>
                <v-flex xs1 style="margin-bottom: 25px;">
                    <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                      leave-active-class="animated fadeOutRight">
                      <v-btn  v-if="attendance_date || search " outlined color="primary"
                        text class="mt-n7-2 staff_clear_filter"
                        @click="attendance_date = '', search = '', get()">
                        Clear
                      </v-btn>
                    </transition>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs1>
                  <search-button
                    :permission="true"
                    style=""
                    @action="get()"
                  ></search-button>
                </v-flex>
              </v-card-title>
            </v-card>
            <v-data-table
              :headers="headers"
              :items="form.items.data"
              :options.sync="pagination"
              :footer-props="footerProps"
              :server-items-length="form.items.meta.total"
              :loading="form.loading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                    <td>{{ index + form.items.meta.from }}</td>
                    <td class="text-xs-left">{{ item.name }}</td>
                    <td class="text-xs-left">
                        <v-icon small color="primary">email</v-icon>
                        {{ item.email || "N/A" }} <br />
                        <v-icon color="warning" small>phone</v-icon>
                        <strong>{{ item.primary_mobile || "N/A" }}</strong>
                    </td>
                    <td>
                        {{ item.attendance_date || "N/A" }} <br />
                        <div v-if="item.attendance_date">
                            <span style="font-size: 10px; color: #666"
                            >({{ item.attendance_time_12_hour_fromat || "-" }})</span>
                         </div>
                    </td>
                    <td>
                        {{ item.left_at_12_hour_format || "N/A" }} <br />
                    </td>
                    <td class="text-xs-left">
                        <v-tooltip bottom v-if="item.note && item.note.length > 10">
                            <template v-slot:activator="{ on }">
                                <span v-on="on"> {{ item.note.cleanHtml(15) }} </span>
                            </template>
                            <span>{{ item.note }}</span>
                        </v-tooltip>
                        <span v-else> {{ item.note }}</span>
                    </td>
                    <td class="text-right">
                        <edit-button
                            v-if="item.id &&  ($auth.isAdmin() || $auth.loggedInRole().slug === 'administrator' || $auth.loggedInRole().slug === 'receptionist' )"
                            :permission="true"
                            @agree="form.edit(item), updateDialog=true"
                        />
                    </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-dialog v-model="updateDialog" persistent max-width="400px">
            <v-card>
            <v-card-title class="primary white--text">
                <span class="title">Update</span>
            </v-card-title>
            <v-card-text class="pb-1 pt-4">
                <v-form
                  ref="form"
                  v-model="valid"
                  :lazy-validation="lazy"
                  @submit.prevent="save"
                  @keydown.native="form.errors.clear($event.target.name)"
                  @keyup.enter="save"
                >
                  <v-container grid-list-md>
                      <v-layout row wrap>
                          <v-flex xs6>
                              <v-text-field outlined dense v-model="form.attendance_time" label="Entry at" readonly
                                  class="pt-0" @click="timePickerModal = true, timePickerFor = 'entry'">
                              </v-text-field>
                          </v-flex>
                          <v-flex xs6>
                              <v-text-field outlined dense v-model="form.left_at" label="Exit at" readonly
                                  class="pt-0" @click="timePickerModal = true, timePickerFor = 'exit'">
                              </v-text-field>
                          </v-flex>
                          <v-flex xs12>
                              <v-textarea outlined name="Note" label="Note (optional)" v-model="form.note"
                                  required></v-textarea>
                          </v-flex>
                          
                      </v-layout>
                  </v-container>
                    
                        
                  
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="warning"
                text
                @click="(updateDialog = false), form.reset()"
                >Close</v-btn
                >
                <v-btn
                :loading="form.loading"
                color="success"
                text
                @click="update()"
                >Update</v-btn
                >
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog ref="dialog" v-model="timePickerModal" :return-value.sync="form.attendance_time"
            persistent width="290px">
            <v-time-picker :allowed-minutes="allowedMinutes"
                v-if="timePickerModal && timePickerFor === 'entry'" v-model="form.attendance_time"
                full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timePickerModal = false, timePickerFor = ''">
                    Cancel
                </v-btn>
                <v-btn text color="primary"
                    @click="$refs.dialog.save(form.attendance_time), timePickerFor = ''">
                    OK
                </v-btn>
            </v-time-picker>

            <v-time-picker :allowed-minutes="allowedMinutes"
                v-if="timePickerModal && timePickerFor === 'exit'" v-model="form.left_at"
                full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timePickerModal = false, timePickerFor = ''">
                    Cancel
                </v-btn>
                <v-btn text color="primary"
                    @click="form.left_at !== '' ? timePickerModal = false : '', timePickerFor = ''">
                    OK
                </v-btn>
            </v-time-picker>
        </v-dialog>
      </v-layout>
    </v-container>
  </template>
  <script>
  import Form from "@/library/Form";
  import Mixins from "@/library/Mixins";
  import EditButton from "../../../../../components/EditButton.vue";

  import { redirectDownloadUrl } from "../../../../../library/helpers";

  let typingTimer;
  let doneTypingInterval = 600;
  
  const NepaliDate = require("nepali-date-js");
  const dt = new NepaliDate();
  const year = parseInt(dt.getYear());
  
  export default {
    mixins: [Mixins],
    data: () => ({
        timePickerModal: false,
        timePickerFor: '',
        updateDialog: false,
        valid: true,
        lazy: false,
        isLoading: false,
        search: "",
        headers: [
            { text: "#", align: "left", value: "id", width: "5%", sortable: false },
            { text: "Name", align: "left", value: "fname", width: "30%" },
            {
              text: "Contact",
              align: "left",
              value: "contact",
              sortable: false,
              width: "20%" 
            },
            { text: "Attendance Date", align: "left", value: "attendance_date", width: "10%"},
            { text: "Left At", align: "left", value: "left_at", width: "8%" },
            { text: "Note ", align: "note", value: "left_at", width: "17%"},

            { text: "Action", align: "right", sortable: false,  width: "10%" },
        ],
       
        form: new Form(
        {
            "attendance_time": '',
            "left_at": '',
            "note": '',
        },
        "/api/member-attendance"
        ),
        attendance_date: '',

     
    }),
    watch: {
      pagination: function () {
        this.get();
      },
     
      search(val) {
        if (!val) return this.get();
  
        if (val.length < 1) return;
  
        // Items have already been requested
        if (this.loading) return;
  
        let $this = this;
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function() {
          $this.get();
        }, doneTypingInterval);
      },
    },
    mounted() {
        this.get();
    },
   
    methods: {
        allowedMinutes(v) {
          console.log(v)
            return v % 10 === 0;
        },
        get(params) {
            let extraParams = "&name=" + this.search.toString() + "&attendance_date=" + this.attendance_date;
            let query = [null, undefined].includes(params)
                ? this.queryString(extraParams)
                : params;
            this.form.get(null, query).then(({ data }) => {
                this.guardianRole = data.role;
                this.pagination.totalItems = data.meta.total;
            });
        },
        update(){
            console.log(this.form.id);
            const attendaceId = this.form.id
            if(this.form.day_status === 'half' && this.form.left_at === '') {
                return this.$events.fire("notification", {
                    message: 'Left at time is required',
                    status: "error",
                });
            }
            this.form.put(attendaceId).then((res) => {
                if(res.status === 200) {
                    this.updateDialog = false
                }
            })
        },
        downloadRecord({ type, columns, columnString }) {
          console.log('here')
          redirectDownloadUrl({
            uri: "/download/staff-attendance",
           queryString: `name=${this.search.toString()}`,
        });
    },
    }
  };
  </script>
  
  <style lang="scss">
  .user_add_form {
    .v-chip,
    .v-chip .v-chip__content {
      border-radius: 2px;
      color: white;
    }
  }
  
  .v-timeline--dense:before {
    left: 18px !important;
  }
  
  .v-timeline-item__dot.v-timeline-item__dot--small {
    margin-left: 0px !important;
  }
  
  .first-f {
    background-color: #f44336 !important;
    border-color: #f44336 !important;
  }
  
  .second-s {
    background-color: rgba(255, 28, 26, 0.59) !important;
    border-color: rgba(255, 28, 26, 0.59) !important;
  }
  
  .third-t {
    background-color: yellow !important;
    border-color: yellow !important;
  }
  
  .fourth-f {
    background-color: #e48d0d !important;
    border-color: #e48d0d !important;
  }
  
  .fifth-fi {
    background-color: #4caf50 !important;
    border-color: #4caf50 !important;
  }
  </style>
  